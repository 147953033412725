/*
 * @Author: liyu
 * @Date: 2022-05-16 10:29:19
 * @LastEditTime: 2022-06-16 14:58:14
 * @Description: file content
 * @FilePath: /sensors-www/src/components/ManageUnit/CourseCard/index.tsx
 */
import Button from 'components/Base/Button'
import React from 'react'
import classnames from 'classnames'
import * as styles from './index.module.less'

interface CourseCardProps {
  className?: string
  thumbClassName?: string
  title?: string
  thumb?: string
  descList?: {
    label?: string
    value?: string
    list?: string[]
  }[]
  buttonText?: string
  buttonHref?: string
}
const CourseCard: React.FC<CourseCardProps> = ({
  className,
  thumbClassName,
  title,
  thumb,
  descList,
  buttonText,
  buttonHref,
}) => {
  return (
    <div>
      <div className={classnames('block lg:hidden text-[2.4rem] leading-[3.6rem] mb-[1rem]', className)}>{title}</div>
      <div className={classnames('flex flex-col lg:flex-row justify-center items-center', styles.CourseCard)}>
        <a
          className={classnames('block video-player w-full lg:w-[486px] h-auto lg:h-[286px]', thumbClassName)}
          href={buttonHref}
          target="_blank"
        >
          <img className="lg:h-full" src={thumb} alt="" />
          <span className="play-btn"></span>
        </a>
        <div className="video-content flex flex-col justify-between w-100% lg:max-w-[588px] h-auto lg:h-[286px] pl-0 lg:pl-[24px]">
          <div>
            <div className="video-title hidden lg:block">{title}</div>
            {descList?.map((item, index) => {
              if (item.value) {
                return (
                  <div key={index} className="video-dsc">
                    {item?.label}：{item?.value}
                  </div>
                )
              }
              if (item.list) {
                return (
                  <div key={index} className="video-massage">
                    <div className="video-lightspot">{item?.label}：</div>
                    <div className="video-type">
                      <ul>
                        {item?.list.map((str, index) => (
                          <li key={index}>{str}</li>
                        ))}
                      </ul>
                    </div>
                  </div>
                )
              }
            })}
          </div>
          <div className="video-free-btn text-center lg:text-left mt-[20px] lg:mt-0">
            <Button shape="circle" btnType="primary" href={buttonHref} target="_blank">
              {buttonText}
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CourseCard
