// extracted by mini-css-extract-plugin
export var ClientContainer = "index-module--ClientContainer--Do+Zs";
export var ClientWrap = "index-module--ClientWrap--PB4eV";
export var Retail = "index-module--Retail--TPaLN";
export var RetailBrand = "index-module--RetailBrand--XAu8X";
export var SchemeButtonGroup = "index-module--SchemeButtonGroup--+orF5";
export var SolutionsSwiper = "index-module--SolutionsSwiper--jkE9j";
export var SolutionsSwiperContainer = "index-module--SolutionsSwiperContainer--79sFr";
export var cargoCard = "index-module--cargoCard---L29p";
export var reailBrandProduct = "index-module--reailBrandProduct--tRf36";
export var retailBrandFooter = "index-module--retailBrandFooter--zYCvw";
export var retailCargo = "index-module--retailCargo--EHcgS";