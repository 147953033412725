import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import classnames from 'classnames'
import { RetailBrandState } from '../retail/index.zh'
import Layout from 'layouts/zh'
import * as styles from './index.module.less'

const RetailClothe: React.FC = (props) => {
  const { retailClotheYaml: datas } = useStaticQuery(graphql`
    query {
      retailClotheYaml {
        title
        description
        keywords
        saTitle
        banner_title
        banner_Desc
        banner_buttonText
        banner_buttonHref
        banner_qrcodeButtonText
        banner_qrcodeUrl
        banner_sectionTwoDesc
        banner_sectionTwoTitle
        banner_sectionTwoTitleMb
        banner_dataSource {
          buttonHref
          buttonText
          dataTitle
          descList
          qrcodeUrl {
            publicURL
          }
        }
        banner_icon {
          publicURL
        }
        banner_icon_mb {
          publicURL
        }
        banner_list {
          isActive
          name
          url
        }
        product_dataList {
          btnHref
          btnText
          dataDsc
          dataMsg
          dataTitle
          imgUrl {
            publicURL
          }
          imgUrlMb {
            publicURL
          }
        }
        product_title
        product_titleMb
        digitization_btnHref
        digitization_btnText
        digitization_desc
        digitization_orderHref
        digitization_orderText
        digitization_title
        panorama_btnCode {
          publicURL
        }
        panorama_btnText
        panorama_desc
        panorama_imgUrl {
          publicURL
        }
        panorama_btnText_two
        panorama_btnText_two_url
        panorama_title
        panorama_titleMb
        software_buttonText
        software_qrcode {
          publicURL
        }
        software_sectionCardList {
          cover {
            publicURL
          }
          title
          desc
        }
        software_title
        software_titleMb
        tools_buttonLink
        tools_buttonText
        tools_descList {
          label
          value
          list
        }
        tools_thumb {
          publicURL
        }
        tools_title
        cargo_dataList {
          btnHref
          btnText
          dataMsg
          dataTitle
          imgUrl {
            publicURL
          }
          imgUrlMb {
            publicURL
          }
        }
        cargo_title
        cargo_titleMb
      }
    }
  `)
  return (
    <Layout {...props}>
      <RetailBrandState className={classnames(styles.RetailClothe)} retailBrandYaml={datas} />
    </Layout>
  )
}

export default RetailClothe
