import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import classnames from 'classnames'
import Button from 'components/Base/Button'
import Seo from 'components/seo'
import Tab from 'components/ManageUnit/Tab'
import EntertainmentBanner from 'components/Entertainment/EntertainmentBanner'
import CourseCard from 'components/ManageUnit/CourseCard'
import SectionSubstance, { SectionSubstanceBody } from 'components/SectionSubstance'
import CargoCard from 'components/CargoCard'
import ScanCodeConsultButton from 'components/ScanCodeConsultButton'
import Panorama from 'components/Panorama'
import ProductCard from 'components/ProductCard'
import CardList from 'components/ManageUnit/CardList'
import ArticleFooter from 'components/ArticleFooter'
import Layout from 'layouts/zh'
import * as styles from './index.module.less'

const RetailBrand: React.FC = (props) => {
  const { retailBrandYaml } = useStaticQuery(graphql`
    query {
      retailBrandYaml {
        title
        description
        keywords
        saTitle
        banner_dataSource {
          buttonHref
          buttonText
          dataTitle
          descList
          qrcodeUrl {
            publicURL
          }
        }
        banner_Desc
        banner_buttonHref
        banner_buttonText
        banner_icon {
          publicURL
        }
        banner_icon_mb {
          publicURL
        }
        banner_list {
          isActive
          name
          url
        }
        banner_qrcodeButtonText
        banner_qrcodeUrl
        banner_sectionTwoDesc
        banner_sectionTwoTitle
        banner_sectionTwoTitleMb
        banner_title
        banner_titleMb
        cargo_dataList {
          btnHref
          btnText
          dataMsg
          dataTitle
          imgUrl {
            publicURL
          }
          imgUrlMb {
            publicURL
          }
        }
        cargo_title
        cargo_titleMb
        digitization_btnHref
        digitization_btnText
        digitization_desc
        digitization_orderHref
        digitization_orderText
        digitization_title
        panorama_btnText
        panorama_btnCode {
          publicURL
        }
        panorama_desc
        panorama_imgUrl {
          publicURL
        }
        panorama_title
        panorama_titleMb
        software_buttonText
        software_qrcode {
          publicURL
        }
        software_sectionCardList {
          cover {
            publicURL
          }
          desc
          title
        }
        software_title
        software_titleMb
        product_dataList {
          btnHref
          btnText
          dataDsc
          dataMsg
          dataTitle
          imgUrl {
            publicURL
          }
          imgUrlMb {
            publicURL
          }
        }
        product_title
        product_titleMb
        panorama_btnCode {
          publicURL
        }
        panorama_btnText
        panorama_desc
        panorama_imgUrl {
          publicURL
        }
        panorama_btnText_two
        panorama_btnText_two_url
        panorama_title
        panorama_titleMb
        tools_buttonText
        tools_buttonLink
        tools_descList {
          label
          list
          value
        }
        tools_thumb {
          publicURL
        }
        tools_title
      }
    }
  `)

  return (
    <Layout {...props}>
      <RetailBrandState retailBrandYaml={retailBrandYaml} isCardListLength={true} />
    </Layout>
  )
}

interface RetailBrandStateProps {
  retailBrandYaml: any
  className?: string
  /** CardList 的 length 是 4, 为 true */
  isCardListLength?: boolean;
  xGap?: number;
}

export const RetailBrandState: React.FC<RetailBrandStateProps> = ({
  retailBrandYaml,
  className,
  isCardListLength,
  xGap,
}) => {
  return (
    <>
      <Seo
        title={retailBrandYaml?.title}
        description={retailBrandYaml?.description}
        keywords={retailBrandYaml?.keywords}
        saTitle={retailBrandYaml?.saTitle}
      />
      <main className={classnames(styles.RetailBrand, className)}>
        {/* banner + 二屏轮播 */}
        <EntertainmentBanner
          iconClassName="bannerIcon"
          bannerDescClassName="banner-desc"
          bannerClassName="retail-banner"
          bannerTitleClassName='banner-title'
          sectionTwoWrapClassName="retail-sectionTow"
          bannerCenter
          bannerTitle={retailBrandYaml?.banner_title}
          bannerTitleMobile={retailBrandYaml?.banner_titleMb}
          bannerDesc={retailBrandYaml?.banner_Desc}
          bannerIcon={retailBrandYaml?.banner_icon?.publicURL}
          bannerIconMB={retailBrandYaml?.banner_icon_mb?.publicURL}
          sectionTwoTitle={retailBrandYaml?.banner_sectionTwoTitle}
          sectionTwoDesc={retailBrandYaml?.banner_sectionTwoDesc}
          sectionTwoTitleMB={retailBrandYaml?.banner_sectionTwoTitleMb}
          dataSource={retailBrandYaml?.banner_dataSource?.map((item) => ({ ...item, imgUrl: item?.qrcodeUrl?.publicURL }))}
          slotBannerButton={
            <Button className="mt-[30px]" btnType="white" shape="circle" target="_blank" href={retailBrandYaml?.banner_buttonHref}>
              {retailBrandYaml?.banner_buttonText}
            </Button>
          }
          slotSectionTwoMobileButton={
            <Button className="banner-btn" btnType="primary" shape="circle" href={retailBrandYaml?.banner_buttonHref}>
              {retailBrandYaml?.banner_qrcodeButtonText}
            </Button>
          }
          slotTab={
            <Tab
              className="pt-[20px]"
              textColor="text-[#902BF8]"
              bgColor="retail-tab-bg"
              shadow="shadow-[0_10px_30px_0_rgba(107,125,228,0.3)]"
              list={retailBrandYaml?.banner_list}
            />
          }
        />
        {/* 为什么选择神策 */}
        <SectionSubstance
          titleTextAlignMb="left"
          title={retailBrandYaml?.software_title}
          titleMb={retailBrandYaml?.software_titleMb}
          className="toolsSoftware"
        >
          <SectionSubstanceBody className="toolsBody">
            <CardList
              isHeight={false}
              cardClassName={classnames({'basis-[25%]': isCardListLength})}
              titleColor="#1F2D3D"
              coverWidth={180}
              coverHeight={180}
              autoplay
              yGap={40}
              xGap={xGap || 35}
              mYGap={20}
              mXGap={0}
              datas={retailBrandYaml?.software_sectionCardList}
            />
            <div className="retail-btn">
              <ScanCodeConsultButton
                buttonText={retailBrandYaml?.software_buttonText}
                shape="circle"
                qrcode={retailBrandYaml?.software_qrcode?.publicURL}
              />
            </div>
          </SectionSubstanceBody>
        </SectionSubstance>
        {/* 包含视频 */}
        {retailBrandYaml?.tools_title && (
          <SectionSubstance className="toolsCustomer">
            <CourseCard
              className="toolsContainer"
              title={retailBrandYaml?.tools_title}
              thumb={retailBrandYaml?.tools_thumb.publicURL}
              buttonText={retailBrandYaml?.tools_buttonText}
              buttonHref={retailBrandYaml?.tools_buttonLink}
              descList={retailBrandYaml?.tools_descList}
            />
          </SectionSubstance>
        )}
        {/* 解决方案 全景图 */}
        <SectionSubstance
          titleTextAlignMb="left"
          className="panorama"
          titleMb={retailBrandYaml?.panorama_titleMb}
          title={retailBrandYaml?.panorama_title}
          descText={retailBrandYaml?.panorama_desc}
        >
          <Panorama
            className="panorama-container"
            btnText={retailBrandYaml?.panorama_btnText}
            btnCode={retailBrandYaml?.panorama_btnCode.publicURL}
            imgUrl={retailBrandYaml?.panorama_imgUrl.publicURL}
            btnTextTwo={retailBrandYaml?.panorama_btnText_two}
            btnTextTwoUrl={retailBrandYaml?.panorama_btnText_two_url}
          />
          
        </SectionSubstance>
        {/* 更多干货 */}
        <SectionSubstance
          className={styles.retailCargo}
          titleMb={retailBrandYaml?.cargo_titleMb}
          title={retailBrandYaml?.cargo_title}
          titleTextAlign="left"
        >
          <CargoCard
            className={styles.cargoCard}
            dataSource={retailBrandYaml?.cargo_dataList?.map((item) => ({
              ...item,
              imgUrl: item?.imgUrl?.publicURL,
              imgUrlMb: item?.imgUrlMb?.publicURL,
            }))}
          />
        </SectionSubstance>
        {/* 了解产品 */}
        <SectionSubstance
          className={styles.reailBrandProduct}
          title={retailBrandYaml?.product_title}
          titleMb={retailBrandYaml?.product_titleMb}
          titleTextAlign="left"
        >
          <ProductCard
            className="product-card"
            dataSource={retailBrandYaml?.product_dataList?.map((item) => ({
              ...item,
              imgUrl: item?.imgUrl?.publicURL,
              imgUrlMb: item?.imgUrl?.publicURL,
            }))}
          />
        </SectionSubstance>
        {/* 尾部 */}
        <ArticleFooter
          className={styles.retailBrandFooter}
          title={retailBrandYaml?.digitization_title}
          gameDsc={retailBrandYaml?.digitization_desc}
          btnText={retailBrandYaml?.digitization_btnText}
          btnHref={retailBrandYaml?.digitization_btnHref}
          orderText={retailBrandYaml?.digitization_orderText}
          orderHref={retailBrandYaml?.digitization_orderHref}
        />
      </main>
    </>
  )
}

export default RetailBrand
