import React from 'react'
import Button from 'components/Base/Button'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Pagination, Scrollbar, A11y, Autoplay, Navigation, Virtual, EffectFade } from 'swiper'
import classnames from 'classnames'
import 'swiper/swiper-bundle.css'
import * as styles from './index.module.less'
SwiperCore.use([Pagination, Scrollbar, A11y, Autoplay, Navigation, Virtual, EffectFade])

interface ProductCardProduct {
  dataSource?: {
    dataTitle?: string
    dataMsg?: string
    dataDsc?: string
    btnHref?: string
    btnText?: string
    imgUrl?: string
    imgUrlMb?: string
  }[]
  className?: string
}

const ProductCard: React.FC<ProductCardProduct> = ({ dataSource, className }) => {
  return (
    <div className={classnames(styles.ProductCardContainer, className)}>
      <div className="product-content">
        <ul className="product-card">
          {dataSource?.map((item, index) => (
            <li key={item.dataTitle}>
              <div>
                <img src={item.imgUrl} alt="" />
              </div>
              <div className="card-content">
                <div className="card-msg">{item.dataMsg}</div>
                <div className="card-title">{item.dataTitle}</div>
                <div className="card-dsc">{item.dataDsc}</div>
                <a href={item.btnHref} target="_blank" className="card-more">
                  {item.btnText}
                </a>
              </div>
            </li>
          ))}
        </ul>
        <div className="product-swiper-mb">
          <Swiper
            autoplay={true}
            allowTouchMove={true}
            autoHeight={true}
            pagination={{ type: 'bullets' }}
            speed={800}
            effect={'fade'}
          >
            {dataSource?.map((item) => (
              <SwiperSlide key={item.dataTitle}>
                <Button btnType="text" href={item.btnHref} target="_blank">
                  <div className="swier-content">
                    <div className="swiper-img">
                      <img src={item.imgUrlMb} alt="" />
                    </div>

                    <div className="card-content-mb">
                      <div className="card-msg-mb">{item.dataMsg}</div>
                      <div className="card-title-mb">{item.dataTitle}</div>
                      <div className="card-dsc-mb">{item.dataDsc}</div>

                      <div className="card-more-mb">{item.btnText}</div>
                    </div>
                  </div>
                  <div className="swiper-bottom"></div>
                </Button>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  )
}

export default ProductCard
